import { FeatureStability, Sidebar } from '@g17eco/molecules';
import { QUESTION } from '@constants/terminology';
import { AIAssistant } from './ai-assistant/AIAssistant';
import { Connections } from './connections/Connections';
import { Button } from 'reactstrap';
import { useAppSelector } from '@reducers/index';
import { isStaff } from '@selectors/user';
import { useConnectionContext } from './connections/ConnectionContext';
import { useTabs } from '@hooks/useTabs';
import { AnswerProps } from './ai-assistant/types';

interface Props extends AnswerProps {
  open: boolean;
  toggle: () => void;
  canAccessAITools: boolean;
}

enum Tabs {
  Assistant = 'Assistant',
  Connections = 'Connections',
}

export const AssistantOffcanvas = ({ open, toggle, utrv, utr, canAccessAITools }: Props) => {
  const isUserStaff = useAppSelector(isStaff); // To remove later.

  const { removeConnection } = useConnectionContext();

  const { currentTab, handleChangeTab } = useTabs({
    initialTab: Tabs.Assistant,
    changeTabCallback: removeConnection,
  });

  return (
    <Sidebar
      isOpen={open}
      scrollable
      toggle={toggle}
      backdrop={false}
      header={canAccessAITools ? `${QUESTION.CAPITALIZED_SINGULAR} Assistant` : 'Connections'}
      className='question-assistant shadow-lg'
    >
      <div className='d-flex flex-column'>
        {canAccessAITools ? (
          <>
            {isUserStaff ? (
              <div className='mb-3 d-flex justify-content-between align-items-center'>
                {Object.values(Tabs).map((tab) => (
                  <Button
                    className='flex-fill mr-2'
                    key={tab}
                    color='primary'
                    outline={currentTab !== tab}
                    onClick={() => handleChangeTab(tab)}
                    active={currentTab === tab}
                  >
                    {tab}
                  </Button>
                ))}
                <FeatureStability className='ml-0' stability={'beta'} />
              </div>
            ) : null}
            {currentTab === Tabs.Assistant ? <AIAssistant utr={utr} utrv={utrv} /> : null}
            {currentTab === Tabs.Connections ? <Connections utr={utr} utrv={utrv} /> : null}
          </>
        ) : (
          <Connections utr={utr} utrv={utrv} />
        )}
      </div>
    </Sidebar>
  );
};
